import algoliasearch, {SearchIndex} from 'algoliasearch';
import {Observable, Observer, Subscriber} from 'rxjs';
import {Hit} from '@algolia/client-search';


export interface AlgoliaSearchKeys {
  appId: string;
  apiKey: string;
  index: string;
}

export class AlgoliaSearch {
  private searchIndex!: SearchIndex;

  constructor(private params: AlgoliaSearchKeys) {
    const _client = algoliasearch(
      this.params.appId,
      this.params.apiKey
    );

    this.searchIndex = _client.initIndex(this.params.index);
  }

  search<T>() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    return (observable: Observable<string | null>) => {
      return new Observable((subscriber) => {
        // this function will be called each time this
        // Observable is subscribed to.
        const subscription = observable.subscribe({
          next(value: string | null) {
            if (value && value.length) {
              that.searchIndex.search<T>(value).then((results) => {
                subscriber.next(results.hits);
              }).catch((err) => {
                // We need to make sure we're propagating our errors through.
                subscriber.error(err);
              })
            } else {
              subscriber.next([]);
            }
          },
          error(err) {
            // We need to make sure we're propagating our errors through.
            subscriber.error(err);
          },
          complete() {
            // noop
          },
        });

        // Return the finalization logic. This will be invoked when
        // the result errors, completes, or is unsubscribed.
        return () => {
          subscription.unsubscribe();
        };
      })
    }



  }
}

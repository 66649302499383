export const remoteConfigParamsDev = {
  remoteConfigParams: {
    settings: {
      minimumFetchIntervalMillis: 5000
    }
  }
};

export const remoteConfigParamsProd = {
  remoteConfigParams: {
    settings: {
      minimumFetchIntervalMillis: 3600000
    }
  }
};
